
import { useReducer, useEffect } from 'react';

import {
    Box,
    Button, MenuItem, Typography,
    useTheme,

} from '@mui/material';
import { FormField } from '../StyledComponents/FormField';
import { useUpdateHotelMutation } from './hooks/useUpdateHotelMutation';
import CloseIcon from '@mui/icons-material/Close';
import { useQueryClient } from '@tanstack/react-query';
import TextLanguage from '../../components/data/TextLanguage.json';

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD':
            return {
                ...state,
                [action.fieldName]: action.payload,
            };

        case 'REPLACE':
            return {
                ...action.tempval
            };
        default:
            return state;
    }
}

const initialState = {
    "Name": null,
    "Area": null,
    "Phone": null,
    "Fax": null,
    "Reference": null,
    "Contract": null,
    "ContractPhoneNotes": null,
    "Region": null,
    "Lat": 36.8685887,
    "Lng": 30.6484849,
    "NameComm": null,
    "ReferenceComm": null,
    "ContractComm": null,
    "ContractPhoneNotesComm": null,
    "AreaComm": null




};


export const HotelUpdateForm = ({ lang, setsnackbarStatus, selectedValue, setSelectedValue, setFormModalOpen }) => {

    const queryClient = useQueryClient();

    const [hotelUpdater, dispatch] = useReducer(reducer, initialState);
    const hospitalData = queryClient.getQueryData(['hospitalData']);

    const theme = useTheme();

    const handleFieldChange = (fieldName) => (e) => {

        dispatch({
            type: 'SET_FIELD',
            fieldName: fieldName,
            payload: e.target.value,
        });

    };


    const hotelUpdateMutation = useUpdateHotelMutation({ searchMode: true, setSelectedValue, setFormModalOpen, setsnackbarStatus });

    const handleReplaceHotelUpdater = (input) => {
        dispatch({
            type: 'REPLACE',
            tempval: input,
        });
    }

    useEffect(() => {
        handleReplaceHotelUpdater(selectedValue);
    }, [selectedValue])


    const handleSubmitEdit = () => {
        hotelUpdateMutation.mutate({ updaterData: hotelUpdater });
    }


    return <div className='PatientCaseInfoCreateForm'>
        <div className='HotelEditFormTitle'>
            <Typography variant='h1'
                sx={{
                    fontSize: '1.5rem',
                    color: 'white',
                    fontWeight: 'bolder',
                    pointerEvents: 'none',
                    userSelect: 'none',
                    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}

            >
                {TextLanguage["HotelUpdateForm"][lang]}
            </Typography>
            <div className='HotelEditFormButton' style={{ right: 0 }}>
                <Button onClick={() => setFormModalOpen(false)}>
                    <CloseIcon sx={{ color: 'white', fontSize: '1.5rem' }} />
                </Button>
            </div>
        </div>


        <Box className='HotelEditFormGroup' style={{ gap: '1rem', padding: 0 }}>

            <Box sx={{
                display: 'grid',
                gridTemplateRows: 'repeat(5, 1fr) 2fr',
                overflow: 'auto',
                width: '100%',
                height: '100%',
                padding:'2rem',
                gap: '2rem',
                '@media screen and (max-width: 1500px)': { gridTemplateRows: 'auto' }
            }}>

                <FormField
                    label={"Name"}
                    value={hotelUpdater.Name}
                    onChange={handleFieldChange('Name')} />
                <FormField
                    label={"Phone Number"}
                    value={hotelUpdater.Phone}
                    onChange={handleFieldChange('Phone')} />

                <FormField
                    id="outlined-select-contractedHosp"
                    select
                    value={hotelUpdater.Contract}
                    onChange={handleFieldChange('Contract')}
                    defaultValue={hotelUpdater.Contract}
                    helperText="Select Contracted Hospital"
                    sx={{ width: '100%', maxWidth: '100%' }}
                >
                    <MenuItem value={null}>None</MenuItem>
                    {hospitalData?.status === 200 && hospitalData?.data?.map((option) => (
                        <MenuItem key={`Cont-Hosp-Opt-${option?.Name}`} value={option?.Name}>
                            {option?.Name}
                        </MenuItem>
                    ))}
                </FormField>



                <FormField
                    label={"Fax"}
                    value={hotelUpdater.Fax}
                    onChange={handleFieldChange('Fax')} />
                <FormField
                    label={"Reference"}
                    value={hotelUpdater.Reference}
                    onChange={handleFieldChange('Reference')} />



                <FormField
                    label="Notes"
                    multiline
                    rows={3}
                    setheight={120}
                    value={hotelUpdater.NameComm}
                    onChange={handleFieldChange('NameComm')}
                    sx={{ width: '100%', maxWidth: '100%' }}
                />
            </Box>


            <div className='HotelEditFormTitle' style={{ borderTop: '2px solid rgb(55, 73, 118)', width: '100%', }}>

                <Button sx={{
                    background: theme.palette.gradient.d,
                    border: '1px solid rgba(108, 108, 108,0.65)',
                    color: 'white',
                    padding: '5px 15px 5px 15px',
                    width: '100%', fontWeight: 'bold'
                }}
                    onClick={handleSubmitEdit}>
                    {TextLanguage["Submit"][lang]}
                </Button>
            </div>

        </Box>



    </div>;
}
