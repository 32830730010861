
import { useReducer, useState, useEffect } from 'react';

import { Autocomplete, Box, Button, Chip,  MenuItem,  Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputMask from 'react-input-mask';
import { FormField } from '../StyledComponents/FormField';
import TextLanguage from '../data/TextLanguage.json';

import dayjs from 'dayjs';
import { useCreatePatientMutation } from '../../hooks/useCreatePatientMutation';



import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { useQueryClient } from '@tanstack/react-query';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import CloseIcon from '@mui/icons-material/Close';

import { matchSorter } from 'match-sorter';

import { normalizeString } from '../../functions/normalizeString';
import {  useTheme } from '@mui/material/styles';
import useResizeObserver from './hooks/useResizeObserver';
import { PolicySearch } from './components/PolicySearch';



const BoxStyle = {
    gridColumn: 'span 2', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '8px 3rem',
    gridTemplateRows: '1fr 1fr',
    padding: '8px 2rem', background: 'rgba(5, 5, 5, 0.15)', boxShadow: 'inset 0px 0px 16px 2px rgba(0,0,0,0.15)',
    borderBottom: '2px solid rgb(55, 73, 118)', alignItems: 'center'
}

function checkInput(input) {
    return (input === '' || input === undefined || input === null);
}

const LDMVariableMap = {
    'Name Sorname': 'name',
    'Ref No': 'LDMCaseNo',
    'Date of Admission': 'admissionDate',
    'Date of birth': 'dob',
    'Mobile Phone': 'mobilePhone',
    'Hotel / Region': 'LDMHotelLabel',
    'Room': 'hotelRoom',
    'IC / NO': 'InsuranceCo',
    'Policy No :': 'policyNo',
    'Franchise': 'franchise',
    'Complaints': 'complaints',
    'Note': 'notes'
};

const LDMInsuranceCoMap = {
    "Insurance company eb": "ЕВРОИНС",
    "RUSSIAN INSURANCE COMPANY \"EUROINS\"": "ЕВРОИНС",
    "Insurance company erv": "ERV_Ukraine",
    "Insurance company \"RESO-GARANTIA\"": "Reso-Garant",
    "Insurance company Pegas": "Pegas_GPI",
    "Insurance company ck": "СK_NEO",
    "Insurance company nmd": "Nomad_Kazakhstan",
    "\“NOMAD  Insurance\”(Kazakhstan)  Insurance Company JSC": "Nomad_Kazakhstan",
    "Insurance company ge": "Gelios",
    "Insurance company nmb": "Nomad_Bishkek",
    "Insurance company nmbb": "Nomad_Bishkek",
    "Insurance company nmdarm": "Nomad_Armenia",
    "Insurance company alf": "Alfa",
};
const LDMFranchiseMap = {
    '0,00 USD': '0$',
    '0,00 EUR': '0€',
    '25,00 USD': '25$',
    '30,00 USD': '30$',
    '25,00 EUR': '25€',
    '30,00 EUR': '30€',
};


function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD':
            return {
                ...state,
                [action.fieldName]: action.payload,
            };

        case 'REPLACE':
            return {
                ...action.tempval
            };
        default:
            return state;
    }
}


const initialStatePatient = {
    mailInfoTo: '',
    mailInfoCc: '',
    userIDInsert: '',
    hospID: '',
    name: '',
    admissionDate: '',
    dob: '',
    mobilePhone: '',
    hotelRoom: '',
    hotel: '',
    policyNo: '',
    InsuranceCo: '',
    complaints: '',
    notes: '',
    franchise: '25$',
    InsurancePeriodStart: '', // (DD.MM-DD.MM)YYYY
    InsurancePeriodEnd: '',
};

const currencies = [
    {
        value: '0$',
        label: '0 $',
    },
    {
        value: '0€',
        label: '0 €',
    },
    {
        value: '25$',
        label: '25 $',
    },
    {
        value: '30$',
        label: '30 $',
    },
    {
        value: '25€',
        label: '25 €',
    },
    {
        value: '30€',
        label: '30 €',
    },
];



export const CreatePatientForm = ({
    selectedValue,
    setFormModalOpen,
    setsnackbarStatus,
    lang,

}) => {

    // residency, city
    // policy date


    const [patientForm, dispatchPatient] = useReducer(reducer, initialStatePatient);

    const queryClient = useQueryClient();

    const theme = useTheme();

    const InsuranceCompanyListData = queryClient.getQueryData(['insCoList']);
    const HospitalUserData = queryClient.getQueryData(['hospitalUserList']);






    useEffect(() => {
        dispatchPatient({
            type: 'SET_FIELD',
            fieldName: 'hotel',
            payload: selectedValue?.Name,
        });
    }, []);

    useEffect(() => {
        dispatchPatient({
            type: 'SET_FIELD',
            fieldName: 'hotel',
            payload: selectedValue?.Name,
        });
    }, [selectedValue])


    const handleFieldChange = (fieldName) => (e) => {

        dispatchPatient({
            type: 'SET_FIELD',
            fieldName: fieldName,
            payload: e.target.value,
        });

    };

    const [dateTargetAdmission, setdateTargetAdmission] = useState(dayjs());
    const [dateTargetDob, setdateTargetDob] = useState(dayjs());
    const [dateTargetPolicyStart, setdateTargetPolicyStart] = useState(dayjs());
    const [dateTargetPolicyEnd, setdateTargetPolicyEnd] = useState(dayjs());



    const [uploadProgress, setUploadProgress] = useState(0);
    const [submissionState, setSubmissionState] = useState(false);


    useEffect(() => { handleDateChange('admissionDate')(dateTargetAdmission) }, [dateTargetAdmission])
    useEffect(() => { handleDateChange('dob')(dateTargetDob) }, [dateTargetDob])
    useEffect(() => { handleDateChange('InsurancePeriodStart')(dateTargetPolicyStart) }, [dateTargetPolicyStart])
    useEffect(() => { handleDateChange('InsurancePeriodEnd')(dateTargetPolicyEnd) }, [dateTargetPolicyEnd])


    const handleDateChange = (fieldName) => (date) => {
        let formatDate = date?.$d ? new Date(date?.$d) : new Date(date);
        //formatDate = (formatDate.toLocaleString('en-GB').split(',')[0]);
        dispatchPatient({
            type: 'SET_FIELD',
            fieldName: fieldName,
            payload: formatDate,
        });
    };

    const createPatientMutation = useCreatePatientMutation({ setFormModalOpen });



    const handleSubmitPatient = async (e) => {
        e.preventDefault();
        setSubmissionState(true);
        // Validation

        if (checkInput(patientForm.userIDInsert && patientForm.hospID)) {
            setsnackbarStatus({ open: true, message: 'Please select a Hospital/Clinic', severity: 'error' });
            return;
        } else if (checkInput(patientForm.InsuranceCo)) {
            setsnackbarStatus({ open: true, message: 'Please select an Insurance Company', severity: 'error' });
            return;
        } else if (checkInput(patientForm.franchise)) {
            setsnackbarStatus({ open: true, message: 'Please select Franchise Amount', severity: 'error' });
            return;
        } else if (checkInput(patientForm.name)) {
            setsnackbarStatus({ open: true, message: 'Please enter the Patient\'s Name', severity: 'error' });
            return;
        } else if (checkInput(patientForm.policyNo)) {
            setsnackbarStatus({ open: true, message: 'Please enter a Policy Number', severity: 'error' });
            return;
        } else if (checkInput(patientForm.admissionDate)) {
            setsnackbarStatus({ open: true, message: 'Please enter the Admission Date', severity: 'error' });
            return;
        } else if (checkInput(patientForm.dob)) {
            setsnackbarStatus({ open: true, message: 'Please enter the Patient\'s Date of Birth', severity: 'error' });
            return;
        }


        createPatientMutation.mutate({ patientForm });
    };

    const handlePasteClipboard = () => {
        navigator.clipboard.readText().then(text => {

            let validationStatus = { name: false, dob: false, admissionDate: false, InsuranceCo: false, franchise: false, policyNo: false, LDMCaseNo: false };

            const fullTextSplit = text.split('\n');
            const tableData = fullTextSplit.map(row => row.trim().split('\t'));
            const rows = tableData.filter(row => row.length === 2);
            //console.log(rows, rows.length);
            if (rows.length > 0 && LDMVariableMap[rows[0][0]] === 'name') {
                validationStatus = { ...validationStatus, name: true };
            }


            rows.forEach(row => {
                const key = LDMVariableMap[row[0]];
                const value = row[1];
                switch (key) {
                    case null:
                    case undefined:
                        setsnackbarStatus({ open: true, message: 'Invalid Data', severity: 'error' });
                        break;
                    case 'dob':
                        if (value !== null) {
                            const day = value.split('.')[0];
                            const month = value.split('.')[1];
                            const year = value.split('.')[2];
                            const parsedDate = new Date(`${year}-${month}-${day}`);
                            dispatchPatient({
                                type: 'SET_FIELD',
                                fieldName: 'dob',
                                payload: parsedDate
                            });
                            setdateTargetDob(dayjs(parsedDate));
                            validationStatus = { ...validationStatus, dob: true };

                        }
                        break;
                    case 'admissionDate':
                        if (value !== null) {
                            const day = value.split('.')[0];
                            const month = value.split('.')[1];
                            const year = value.split('.')[2];
                            const parsedDate = new Date(`${year}-${month}-${day}`);
                            dispatchPatient({
                                type: 'SET_FIELD',
                                fieldName: 'admissionDate',
                                payload: parsedDate
                            });
                            setdateTargetAdmission(dayjs(parsedDate));
                            validationStatus = { ...validationStatus, admissionDate: true };
                        }

                        break;
                    case 'InsuranceCo':
                        if (value !== null) {
                            dispatchPatient({
                                type: 'SET_FIELD',
                                fieldName: 'InsuranceCo',
                                payload: LDMInsuranceCoMap[value],
                            });
                            validationStatus = { ...validationStatus, InsuranceCo: true };
                        }
                        break;
                    case 'franchise':
                        //console.log(key, value, LDMFranchiseMap[value]);
                        if (value !== null) {
                            dispatchPatient({
                                type: 'SET_FIELD',
                                fieldName: 'franchise',
                                payload: LDMFranchiseMap[value],
                            });
                            validationStatus = { ...validationStatus, franchise: true };
                        }
                        break;

                    default:
                        if (value !== null) {
                            if (key === 'LDMCaseNo') {
                                validationStatus = { ...validationStatus, LDMCaseNo: true };
                            } else if (key === 'policyNo') {
                                validationStatus = { ...validationStatus, policyNo: true };
                            }
                            dispatchPatient({
                                type: 'SET_FIELD',
                                fieldName: key,
                                payload: value,
                            });
                        }

                        break;
                }

            })

            if (Object.values(validationStatus).every(value => value === true)) {
                setsnackbarStatus({ open: true, message: 'Data Parsed Successfully', severity: 'success' });
            } else if (Object.values(validationStatus).every(value => value === false)) {
                setSubmissionState(true);
                setsnackbarStatus({ open: true, message: 'Invalid Data', severity: 'error' });
            } else if (Object.values(validationStatus).some(value => value === false)) {
                const missingData = Object.keys(validationStatus).filter(key => validationStatus[key] === false);
                const keyStringMap = {
                    name: 'Name',
                    dob: 'Date of Birth',
                    admissionDate: 'Admission Date',
                    InsuranceCo: 'Insurance Company',
                    franchise: 'Franchise',
                    policyNo: 'Policy Number',
                    LDMCaseNo: 'LDM Case Number'
                };
                const missingDataString = missingData.map(data => keyStringMap[data]).join(', ');
                setSubmissionState(true);
                setsnackbarStatus({ open: true, message: `Missing Data: ${missingDataString}`, severity: 'warning' });
            }

        });
    };



    const isLDM = InsuranceCompanyListData?.data?.find((insCo) => insCo.value === patientForm.InsuranceCo)?.type === 'LDM'


    useEffect(() => {
        dispatchPatient({
            type: 'SET_FIELD',
            fieldName: 'mailInfoTo',
            payload: HospitalUserData?.data?.find((option) => option.value === patientForm?.userIDInsert)?.mailInfo,
        });

    }, [patientForm.userIDInsert])





    const filterOptions = (options, { inputValue }) => {
        const normalizedInput = normalizeString(inputValue?.toLowerCase());

        return matchSorter(options, normalizedInput, {
            keys: [option => normalizeString(option?.hospitalName?.toLowerCase())],
            base: 10,
            maxRanking: 10,
        })
    };
    const [showTypography, setShowTypography] = useState(true);

    const parentRef = useResizeObserver((rect) => {
        setShowTypography(rect.width > 800);
    });

    return (<div className='PatientCaseInfoCreateForm'>

        <div ref={parentRef} className='HotelEditFormTitle' >
            <div className='HotelEditFormButton' style={{ left: 0 }}>
                <Button sx={{
                    background: 'rgb(40, 49, 65)', color: 'white', gap: '0.5rem', border: '1px solid rgba(108, 108, 108,0.5)',
                    fontSize: '1.25rem',
                    boxShadow: 'inset 0px 10px 16px -6px rgba(255, 255, 255, 0.1)',
                    padding: '5px 25px 5px 25px', fontWeight: 'bold', borderRadius: '2px',
                    '&:hover': { background: 'rgb(57, 75, 106)' }, whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis', maxHeight: '40px', height: '40px', zIndex: 15
                }}
                    onClick={handlePasteClipboard}
                >
                    <ContentPasteIcon sx={{ color: 'white', fontSize: '1.5rem' }} />
                    {<Typography sx={{ fontWeight: 'bold' }}>{TextLanguage["PasteLDMForm"][lang]}</Typography>}
                </Button>
            </div>
            <Typography variant='h1'
                sx={{
                    fontSize: '1.5rem',
                    color: 'white',
                    fontWeight: 'bolder',
                    pointerEvents: 'none',
                    userSelect: 'none',
                    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}

            >
                {showTypography && TextLanguage["NewPatientForm"][lang]}
            </Typography>
            <div className='HotelEditFormButton' style={{ right: 0 }}>
                <Button onClick={() => setFormModalOpen(false)}>
                    <CloseIcon sx={{ color: 'white', fontSize: '1.5rem', zIndex: 40 }} />
                </Button>
            </div>


        </div>


        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridTemplateRows: 'repeat(4, minmax(150px, 200px)) auto',
                overflow: 'auto',
                width: '100%',
                height: '100%',
                '@media screen and (max-width: 1500px)': { gridTemplateRows: 'auto' }
            }}
        >
            {/* Group 1: 'To', 'Cc' / Hospital */}
            <Box sx={{ ...BoxStyle }}>
                <FormField
                    label={"To"}
                    value={patientForm.mailInfoTo}
                    onChange={handleFieldChange('mailInfoTo')}
                    placeholder='email1, email2, email3'

                    sx={{ '& .MuiFormControl-root': { maxHeight: '60px' }, '& .MuiInputBase-root': { maxHeight: '60px' } }}
                />

                <FormField
                    label={"Cc"}
                    value={patientForm.mailInfoCc}
                    placeholder='email1, email2, email3'
                    onChange={handleFieldChange('mailInfoCc')}
                    sx={{ '& .MuiFormControl-root': { maxHeight: '60px' }, '& .MuiInputBase-root': { maxHeight: '60px' } }}
                />

                <Box sx={{ gridColumn: 'span 2' }}>
                    {HospitalUserData?.status === 200 &&
                        <Autocomplete

                            isOptionEqualToValue={(option, value) =>
                                option?.hospitalName === value?.hospitalName
                            }
                            filterOptions={filterOptions}
                            noOptionsText={"No Matches"}
                            size={'small'}
                            selectOnFocus
                            clearOnBlur
                            value={HospitalUserData?.data?.find(option => option?.value === patientForm?.userIDInsert) || null}
                            options={HospitalUserData?.data?.filter((option) => !option?.disabled)
                                .sort((a, b) => -b?.hospitalName?.localeCompare(a?.hospitalName))}
                            onChange={
                                (e, value) => {
                                    dispatchPatient({
                                        type: 'SET_FIELD',
                                        fieldName: 'hospID',
                                        payload: value?.hospID,
                                    });
                                    dispatchPatient({
                                        type: 'SET_FIELD',
                                        fieldName: 'userIDInsert',
                                        payload: value?.value,
                                    });
                                }
                            }
                            getOptionLabel={(option) => `${option.hospitalName}`}

                            renderInput={(params) => <FormField {...params} label={TextLanguage["Hospital"][lang]} />}
                            sx={{
                                width: '100%', minWidth: '400px !important', height: '100%',
                                '& .MuiFormControl-root': { height: '100%', maxHeight: '60px' }, '& .MuiInputBase-root': { height: '100%', maxHeight: '60px' }
                            }}
                        />
                    }
                </Box>
            </Box>


            {/* Group 2: Insurance / 'Policy Number', 'Policy Start', 'Policy End', franchise */}
            <Box sx={{
                ...BoxStyle, gridTemplateRows: '1fr 1fr auto',
                "@media screen and (max-width: 1500px)": { gridTemplateColumns: 'repeat(1, 1fr)' }

            }}>
                <FormField
                    id="outlined-select-insCo"
                    select
                    label={TextLanguage["InsuranceCompany"][lang]}
                    value={patientForm.InsuranceCo}
                    onChange={handleFieldChange('InsuranceCo')}
                    defaultValue=""
                    error={submissionState && patientForm.InsuranceCo === ''}

                    sx={{
                        '& .MuiInputBase-root': { height: '100%' },
                        height: '100%', width: '100%',
                        '& .MuiSelect-select': {
                            height: '100%',
                            display: 'flex', gap: '1rem', justifyContent: 'space-between',

                        },


                    }}
                >
                    {InsuranceCompanyListData?.data?.map((option) => (
                        <MenuItem key={option.value} value={option.value} sx={{ gap: '1rem', justifyContent: 'space-between' }}>
                            <Typography>{option.label}</Typography>
                            <Chip key={`${option.value}-chip`} variant="outlined"
                                sx={{
                                    border: '1px solid white',
                                    color: 'white',
                                    '& .MuiChip-label': { paddingLeft: 2, paddingRight: 2 },
                                    background: option.type === 'RBK' ? 'rgb(157, 45, 32)' : 'rgb(49,80,125)',
                                    fontWeight: 900, fontSize: '14px',
                                }}
                                label={option.type} />
                        </MenuItem>
                    ))}
                </FormField>

                <PolicySearch
                patientForm={patientForm}
                handleFieldChange={handleFieldChange}
                submissionState={submissionState}
                disabled={isLDM}
                dispatchPatient={dispatchPatient}
                setdateTargetDob={setdateTargetDob}
                setdateTargetPolicyStart={setdateTargetPolicyStart}
                setdateTargetPolicyEnd={setdateTargetPolicyEnd}
                lang={lang}
                />
                {isLDM ?
                    (<div className='form-row PatientFormRow'>
                        <FormField

                            label={"LDM Ref Number"}
                            value={patientForm.LDMCaseNo}
                            onChange={handleFieldChange('LDMCaseNo')}
                            error={isLDM && submissionState && patientForm.LDMCaseNo === ''}
                        />
                    </div>)
                    :
                    (<div className='form-row PatientFormRow' style={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex', flexDirection: 'row', maxHeight: '60px' }}>
                        <DatePicker
                            label={`${TextLanguage["Policy"][lang]} ${TextLanguage["StartDate"][lang]}`}
                            value={dateTargetPolicyStart}
                            sx={{ background: 'linear-gradient(to bottom, rgb(39, 43, 49), rgb(28, 29, 31) 125%)', maxHeight: '60px' }}
                            onChange={(date) => setdateTargetPolicyStart(date)}
                            renderInput={(props) => <FormField {...props} />} />



                        <ArrowRightIcon sx={{ color: 'white', fontSize: '30px' }} />

                        <DatePicker
                            label={`${TextLanguage["Policy"][lang]} ${TextLanguage["EndDate"][lang]}`}
                            sx={{ background: 'linear-gradient(to bottom, rgb(39, 43, 49), rgb(28, 29, 31) 125%)', maxHeight: '60px' }}
                            value={dateTargetPolicyEnd}
                            onChange={(date) => setdateTargetPolicyEnd(date)}
                            renderInput={(props) => <FormField  {...props} />} />
                    </div>)}

                <FormField
                    id="outlined-select-currency"
                    select
                    label={TextLanguage["Franchise"][lang]}
                    value={patientForm.franchise}
                    onChange={handleFieldChange('franchise')}
                    error={submissionState && patientForm.franchise === ''}


                >

                    {currencies?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <Typography>{option.label}</Typography>
                        </MenuItem>
                    ))}
                </FormField>

                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                </div>

            </Box>

            {/* Group 3: 'Name', 'Phone' / 'dob','admDate' */}
            <Box sx={{
                ...BoxStyle, gridTemplateRows: '1fr 1fr', gridTemplateColumns: 'repeat(3,1fr)',
                '@media screen and (max-width: 1500px)': { gridTemplateColumns: 'repeat(1, 1fr)', gridTemplateRows: 'repeat(5, 1fr)', }


            }}>
                <FormField

                    label={TextLanguage["Name"][lang]}
                    value={patientForm.name}
                    onChange={handleFieldChange('name')}
                    error={submissionState && patientForm.name === ''}
                />
                <InputMask
                    mask="+999999999999999"
                    maskChar={null}
                    value={patientForm.mobilePhone}
                    onChange={handleFieldChange('mobilePhone')}
                >
                    {() => <FormField label={TextLanguage["Phone"][lang]} />}

                </InputMask>
                <FormField
                    label={TextLanguage["Residency/City"][lang]}
                    value={patientForm.residency}
                    onChange={handleFieldChange('residency')}

                />
                <DatePicker
                    label={TextLanguage["DateOfBirth"][lang]}
                    value={dateTargetDob}
                    sx={{ background: 'linear-gradient(to bottom, rgb(39, 43, 49), rgb(28, 29, 31) 125%)', maxHeight: '60px' }}
                    onChange={(date) => setdateTargetDob(date)}
                    renderInput={(props) => <FormField  {...props} />} />
                <DatePicker
                    label={TextLanguage["AdmissionDate"][lang]}
                    sx={{
                        background: 'linear-gradient(to bottom, rgb(39, 43, 49), rgb(28, 29, 31) 125%)', maxHeight: '60px',
                    }}
                    value={dateTargetAdmission}
                    onChange={(date) => setdateTargetAdmission(date)}
                    renderInput={(props) => <FormField {...props} />} />
                <FormField

                    label={TextLanguage["Hotel"][lang]}
                    value={patientForm.hotel}
                    onChange={handleFieldChange('hotel')}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                />





            </Box>

            {/* Group 4: 'residency' / 'notes' / 'complaints' */}
            <Box sx={{
                ...BoxStyle, border: 'none', gridTemplateColumns: 'repeat(2, 1fr)',
                gridTemplateRows: '1fr',
                "@media screen and (max-width: 1500px)": { gridTemplateColumns: 'repeat(1, 1fr)', gridTemplateRows: '1fr 1fr', }
            }}>


                <FormField
                    label={TextLanguage["Notes"][lang]}
                    setheight={120}
                    multiline
                    rows={3}
                    value={patientForm.notes}
                    onChange={handleFieldChange('notes')} />
                <FormField
                    label={TextLanguage["Complaints"][lang]}
                    setheight={120}
                    multiline
                    rows={3}
                    value={patientForm.complaints}
                    onChange={handleFieldChange('complaints')} />
            </Box>


            <Box sx={{ ...BoxStyle, height: '100%' }} />



        </Box>
        <div className='HotelEditFormTitle' style={{ borderTop: '2px solid rgb(55, 73, 118)' }}>

            <Button sx={{
                background: theme.palette.gradient.d,
                border: '1px solid rgba(108, 108, 108,0.65)',
                color: 'white',
                padding: '5px 15px 5px 15px',
                width: '100%', fontWeight: 'bold'
            }}
                onClick={handleSubmitPatient}>
                {TextLanguage["Submit"][lang]}
            </Button>
        </div>
    </div>);
}



