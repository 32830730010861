
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config';

export const useUpdateHotelMutation = ({
    searchMode = false ,setSelectedValue, setFormModalOpen,setsnackbarStatus
}) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ updaterData, oldRowData }) => {


            return axios.put(`${config.API_URL}/operator/updateHotel`, updaterData, {
                withCredentials: true,
            });
        },
        onSuccess: (data, variables) => {

            queryClient.invalidateQueries({ queryKey: ['hotelData'] });
            searchMode && setSelectedValue(variables?.updaterData);
            searchMode && setFormModalOpen(false);
        },
        onError: (error, variables) => {
            //console.log(variables);
            setsnackbarStatus({ open: true, message: 'There was an error during the update', severity: 'error' });
            queryClient.setQueryData(['hotelData'], (prevData) => {
                const updatedData = prevData?.data?.map((hotel) =>
                    hotel._id === variables.oldRowData._id ? variables.oldRowData : hotel
                );
                return { ...prevData, data: updatedData };
            });

        }
    });
}
