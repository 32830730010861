




export function xDateFormatter(xvalDate) {

    let xDateMonthHolder = ''
    let xDateYearHolder = ''
    let xdatecount = 0;
    //console.log(xvalDate);
    let day = xvalDate?.split('.')[0];
    let month = xvalDate?.split('.')[1];
    let year = xvalDate?.split('.')[2];
    if (month === xDateMonthHolder) { xdatecount += 1; } else { xdatecount = 0 };
    xDateMonthHolder = month;
    xDateYearHolder = year;
    if (month === xDateMonthHolder && xdatecount > 2) { month = ''; }
    if (year === xDateYearHolder) { year = '' };

    if (day[0] === '0') { day = day?.slice(1); };

    let date = month ? `${day}-${month}` : `${day}`;

    return date

}

