import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config";

export const useInsuranceDataQuery = ({ searchPolicy }) => {

    async function fetchInsuranceData() {
        return await axios.get(
            `${config.API_URL}/operator/insuranceData?policyNo=${searchPolicy.policyNo}&InsCo=${searchPolicy.InsuranceCo}`,  // Pass the filtersData directly as the second argument
            {
                withCredentials: true
            }
        );
    }

    return useQuery(
        {
            queryKey: ['insCoData', {
                policyNo: searchPolicy.policyNo,
                InsuranceCo: searchPolicy.InsuranceCo
            }],
            queryFn: fetchInsuranceData,
            enabled: !!searchPolicy.policyNo && !!searchPolicy.InsuranceCo,
            staleTime: Infinity,


        }
    )
}