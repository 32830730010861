import * as React from 'react';

import { TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";
import { matchSorter } from 'match-sorter';

import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import remToPx from '../../functions/remToPx.jsx';
import { Autocomplete } from '../StyledComponents/Autocomplete.jsx';
import { StyledPopper } from '../StyledComponents/StyledPopper.jsx';
import ListboxComponent from './ListboxComponent.jsx';
import { useQueryClient } from '@tanstack/react-query';






function LiveSearch({
    setLng, setLat,
    setSelectedValue,
    //selectedValue,
    //lang,

}) {

    const theme = useTheme();
    const queryClient = useQueryClient();

    const hotelData = queryClient.getQueryData(['hotelData']);





    const handleAutocompleteChange = (event, value) => {
        setSelectedValue(value);

        if (value && value.Lng) { //If Selected Value is not null and matches an entry
            setLng(value.Lng); // Set the position of the searched hotel
            setLat(value.Lat);

        }
    };


    const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, {
        keys: ['Name', 'Region'],
        base: 10,
        maxRanking: 10,
    });

    return (

        <Autocomplete
            id="size-small-outlined"
            className="autocomp"
            onChange={handleAutocompleteChange}
            size="small"

            //value={selectedValue}
            PopperComponent={StyledPopper}
            getOptionLabel={(hotel) => `${hotel.Name}`}
            options={hotelData?.data}
            filterOptions={filterOptions}
            isOptionEqualToValue={(option, value) =>
                option.Name.toLowerCase() === value.Name.toLowerCase()
            }
            ListboxComponent={ListboxComponent}
            noOptionsText={"No Matches"}
            renderOption={(props, option, state) => [props, option, state.index]}
            renderInput={(params) => <TextField {...params}
                InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                        <>
                            <InputAdornment position="start">
                                <SearchIcon sx={{ marginLeft: '4px' }} />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                        </>
                    )
                }}
                variant="outlined"
            />}
        />
    );
}


export default LiveSearch;


