import { useState, useEffect } from 'react';

import { Button, Typography, useTheme } from '@mui/material';
import { FormField } from '../../StyledComponents/FormField';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useInsuranceDataQuery } from '../hooks/useInsuranceDataQuery';
import dayjs from 'dayjs';
import TextLanguage from '../../data/TextLanguage.json';

export const PolicySearch = ({
    patientForm,
    handleFieldChange,
    submissionState,
    disabled,
    dispatchPatient,
    setdateTargetDob,
    setdateTargetPolicyStart,
    setdateTargetPolicyEnd,
    lang
}) => {

    const theme = useTheme();
    const [searchPolicy, setSearchPolicy] = useState({ policyNo: '', InsuranceCo: '' });

    const handlePolicyDataSelection = (result) => () => {

        const parsePolicyStart = new Date(result.policyStart);
        const parsePolicyEnd = new Date(result.policyEnd);
        const parseDOB = new Date(result.dob);

        dispatchPatient({
            type: 'SET_FIELD',
            fieldName: 'policyNo',
            payload: result.policyNo,
        });
        dispatchPatient({
            type: 'SET_FIELD',
            fieldName: 'name',
            payload: result.Name,
        });
        dispatchPatient({
            type: 'SET_FIELD',
            fieldName: 'dob',
            payload: parseDOB,
        });
        setdateTargetDob(dayjs(parseDOB));
        dispatchPatient({
            type: 'SET_FIELD',
            fieldName: 'InsurancePeriodStart',
            payload: parsePolicyStart,
        });
        setdateTargetPolicyStart(dayjs(parsePolicyStart));
        dispatchPatient({
            type: 'SET_FIELD',
            fieldName: 'InsurancePeriodEnd',
            payload: parsePolicyEnd,
        });
        setdateTargetPolicyEnd(dayjs(parsePolicyEnd));
    }


    const handleSearchPolicyNo = () => {
        setSearchPolicy({
            policyNo: patientForm.policyNo,
            InsuranceCo: patientForm.InsuranceCo
        });
    }
    const searchPolicyQuery = useInsuranceDataQuery({ searchPolicy });
    useEffect(() => {
        if (searchPolicyQuery.isSuccess) {
            setOpenPolicyDrawer(true);
        }
    }, [searchPolicyQuery.isSuccess])
    const [openPolicyDrawer, setOpenPolicyDrawer] = useState(true);


    return (<div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
            <FormField
                label={TextLanguage['PolicyNo'][lang]}
                value={patientForm.policyNo}
                onChange={handleFieldChange('policyNo')}
                error={submissionState && patientForm.policyNo === ''}
                setheight={60}
                sx={{ borderRadius: '4px 0px 4px 0px', height: '100%', '& .MuiFormControl-root': { height: '100%' }, '& .MuiInputBase-root': { height: '100%' } }} />
            <div style={{ position: 'relative', height: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                <Button sx={{
                    borderRadius: '0px 4px 4px 0px',
                    height: '100%',
                    background: searchPolicyQuery?.data?.status !== 206 ? theme.palette.gradient.a : theme.palette.errorColor.main,
                    '&:hover': { background: searchPolicyQuery?.data?.status !== 206 ? theme.palette.gradient.glow : theme.palette.errorColor.dark }
                }}
                    disabled={!['VUSO', 'Donaris_VIG', 'ETI_Ukraine'].includes(patientForm.InsuranceCo)}
                    onClick={handleSearchPolicyNo}
                >
                    <SearchIcon sx={{
                        color: 'white',
                        fontSize: '1.5rem',
                        height: '100%',
                    }} />
                </Button>
                {!!searchPolicyQuery.isSuccess && searchPolicyQuery?.data?.status !== 206 && <Button sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    padding: '0px',
                    borderRadius: '0px 4px 0px 0px',
                    height: '16px',
                    background: 'rgb(47, 47, 47)',
                    zIndex: 110,
                    borderTop: '1px solid rgb(94, 129, 219)',
                    '&:hover': { background: 'rgb(57, 57, 57)' },
                }}

                    onClick={() => setOpenPolicyDrawer(!openPolicyDrawer)}
                >
                    <ArrowDropDownIcon sx={{
                        color: 'white',
                        fontSize: '1.2rem',
                        height: '100%',

                        transform: openPolicyDrawer ? 'rotate(180deg)' : 'rotate(0deg)',
                    }} />
                </Button>}
            </div>
            <div
                className={`searchDrawer ${searchPolicyQuery.isSuccess && !disabled ? 'searchDrawerOpen' : ''}`}
                style={{
                    height: searchPolicyQuery.isSuccess ? 'auto' : '0px',
                }}>
                {openPolicyDrawer && searchPolicyQuery.isSuccess && !disabled && searchPolicyQuery.isSuccess && [200, 201].includes(searchPolicyQuery?.data?.status) &&
                    <div className='searchDrawerContainer'>
                        {searchPolicyQuery?.data?.data?.map((result, index) => {
                            const selected = result.Name === patientForm.name;
                            return (
                                <Button key={`${result.policyNo}-${result.Name}`}
                                    onClick={handlePolicyDataSelection(result)}
                                    sx={{
                                        color: (selected) ? 'white' : 'darkgray',
                                        display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                                        background: (selected)? theme.palette.mainColor.DarkerDesat : 'transparent',
                                        padding: '8px 12px', width: '100%',
                                        borderBottom: (index + 1) === searchPolicyQuery?.data?.data?.length ? 'none' : '2px solid #ffffff61',
                                        borderRadius: (index + 1) === searchPolicyQuery?.data?.data?.length ? '0px 0px 8px 8px' : '0px',
                                    }}>
                                    <Typography>{result.policyNo}</Typography>
                                    <Typography>{result.Name}</Typography>
                                </Button>
                            );
                        })}
                    </div>}
            </div>
        </div>

    </div>);
}
