import { useState, useEffect, useRef } from 'react';

const useResizeObserver = (callback) => {
  const ref = useRef();

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        callback(entries[0].contentRect);
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [callback]);

  return ref;
};

export default useResizeObserver;