
import React, { useState, useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import markerLocation from '../../../img/markerLocation.png';
import { createCustomMarkerImage } from '../../MarkerElements/createCustomMarkerImage';

const initCenter = [30.697567063732645, 36.904852502255785];


const GeocodeMapComponent = ({ geocodeQuery,
    selectedGeocode,
    mapboxAPIKey,
    dispatch,
    map,
}) => {


    const flyToDuration = 1255;

    const mapContainer = useRef(null);
    //const map = useRef(null);
    const bounds = new mapboxgl.LngLatBounds();
    const [zoom, setZoom] = useState(12);


    const marker = useRef(null); // Hotel Marker
    const hospMarkers = useRef([[], []]);  // Array to store markers
    const popupHosp = useRef(null);

    const defaultZoomUpper = 13;
    const defaultZoomLower = 11;

    const dragGeojson = {
        'type': 'FeatureCollection',
        'features': [
            {
                'type': 'Feature',
                'geometry': {
                    'type': 'Point',
                    'coordinates': initCenter // center of the map on init
                }
            }
        ]
    };
    const canvas = map.current?.loaded() ? map.current.getCanvasContainer() : null;
    const onMove = (e) => {
        //const canvas = map.current.getCanvasContainer();
        const coords = e.lngLat;

        // Set a UI indicator for dragging.
        if (canvas) {
            canvas.style.cursor = 'grabbing';
        }

        // Update the Point feature in `geojson` coordinates
        // and call setData to the source layer `point` on it.
        dragGeojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
        map.current.getSource('dragpoint').setData(dragGeojson);
    }

    const onUp = (e) => {
        // const canvas = map.current.getCanvasContainer();
        const coords = e.lngLat;

        // Print the coordinates of where the point had
        // finished being dragged to on the map.

        //console.log(`Longitude: ${coords.lng} Latitude: ${coords.lat}`);
        dispatch({
            type: 'SET_FIELD',
            fieldName: 'Lat',
            payload: coords.lat,
        });
        dispatch({
            type: 'SET_FIELD',
            fieldName: 'Lng',
            payload: coords.lng,
        });



        if (canvas) {
            canvas.style.cursor = '';
        }
        // Unbind mouse/touch events
        map.current.off('mousemove', onMove);
        map.current.off('touchmove', onMove);
    }

    useEffect(() => {

        mapboxgl.accessToken = mapboxAPIKey;

        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/dark-v11',
            //maxBounds: [[25.9174683253332, 33.914773556029964], [44.206132542282496, 42.538609767504205]],
            center: initCenter,
            zoom: zoom
        });
        if (map.current) {
            map.current.on('load', function () {

                const canvas = map.current.getCanvasContainer();
                map.current.loadImage(
                    createCustomMarkerImage('hsl(208, 96%, 48%)', 35),
                    (error, image) => {
                        if (error) throw error;
                        map.current.addImage('selected-marker', image);
                    });

                map.current.loadImage(
                    createCustomMarkerImage('hsla(215, 61%, 32%, 0.75)', 25),
                    (error, image) => {
                        if (error) throw error;
                        map.current.addImage('normal-marker', image);
                    });



                    map.current.addSource('points',
                    {
                        'type': 'geojson',
                        'data': {
                            'type': 'FeatureCollection',
                            'features': [
                                {

                                }
                            ]
                        }

                    }
                );

                // Add a symbol layer
                map.current.addLayer({
                    'id': 'points',
                    'type': 'symbol',
                    'source': 'points',
                    'layout': {
                        'icon-image': [
                            'case',
                            ['==', ['get', 'marker-type'], 'normal'],
                            'normal-marker',
                            ['==', ['get', 'marker-type'], 'selected'],
                            'selected-marker',
                            'normal-marker'
                        ],
                        // get the title name from the source's "title" property
                        'text-field': ['get', 'text-content'],
                        'text-font': [
                            'Open Sans Semibold',
                            'Arial Unicode MS Bold'
                        ],
                        'text-offset': [0, 1.75],
                        'text-anchor': 'top',
                        'symbol-sort-key': ['get', 'sort-order'],
                        'text-optional': true
                    },
                    'paint': {
                        'text-color': 'white',
                        'text-halo-color': ['get', 'color'],
                        'text-halo-width': 0.5

                    },


                });






                // Draggable Point Layer
                map.current.loadImage(
                        markerLocation,
                    (error, image) => {
                        if (error) throw error;
                        map.current.addImage('dragPoint-marker', image);
                    });


                map.current.addSource('dragpoint', {
                    'type': 'geojson',
                    'data': dragGeojson
                });

                map.current.addLayer({
                    'id': 'dragpointSymbol',
                    'type': 'symbol',
                    'source': 'dragpoint',
                    'layout': {
                        'icon-image': 'dragPoint-marker',
                        // get the title name from the source's "title" property

                        'icon-size': 0.15,
                        'icon-allow-overlap': true,
                        'icon-ignore-placement': true,
                        'icon-anchor': 'bottom',
                        'icon-offset': [0, -10],

                        'icon-optional': true,
                        'icon-sort-key': ['get', 'sort-order'],
                        'icon-color-saturation': 0.05,

                    },

                });

                map.current.on('mouseenter', 'dragpointSymbol', () => {
                    //map.current.setPaintProperty('dragpointSymbol', 'circle-color', '#3bb2d0');
                    canvas.style.cursor = 'move';
                }).on('mouseleave', 'dragpointSymbol', () => {
                    //map.current.setPaintProperty('dragpointSymbol', 'circle-color', '#3887be');
                    canvas.style.cursor = '';
                }).on('mousedown', 'dragpointSymbol', (e) => {
                    // Prevent the default map drag behavior.
                    e.preventDefault();

                    canvas.style.cursor = 'grab';

                    map.current.on('mousemove', onMove);
                    map.current.once('mouseup', onUp);
                }).on('touchstart', 'dragpointSymbol', (e) => {
                    if (e.points.length !== 1) return;

                    // Prevent the default map drag behavior.
                    e.preventDefault();

                    map.current.on('touchmove', onMove);
                    map.current.once('touchend', onUp);
                });



                // Draggable Point Layer End







            });
        }



        return () => {

        }
    }, []);


    useEffect(() => {
        if (map.current && geocodeQuery?.data?.data) {


            map.current.getSource('points')?.setData(
                {
                    ...geocodeQuery.data.data,
                    features: geocodeQuery.data.data.features.map((feature) => {
                        return {
                            ...feature,
                            properties: {
                                ...feature.properties,
                                'text-content': feature.text_tr,
                                'color': 'hsla(0, 0%, 100%, 0.5)',
                                'marker-type': 'normal',
                                'sort-order': 1,

                            }
                        }
                    })

                }
            );
        }


    }, [geocodeQuery.data]);


    useEffect(() => {
        if (!selectedGeocode && !map.current) { return }
        if (selectedGeocode && map.current && selectedGeocode?.geometry?.coordinates) {

            map.current.flyTo({
                center: selectedGeocode?.geometry?.coordinates,
                zoom: defaultZoomUpper,
                essential: true
            });

            map.current.getSource('points')?.setData(
                {
                    ...geocodeQuery.data.data,
                    features: geocodeQuery.data.data.features.map((feature) => {
                        return {
                            ...feature,
                            properties: {
                                ...feature.properties,
                                'text-content': feature.text_tr,
                                'color': feature.id === selectedGeocode.id ? 'hsl(206, 96%, 54%)' : 'hsla(0, 0%, 100%, 0.5)',
                                'marker-type': feature.id === selectedGeocode.id ? 'selected' : 'normal',
                                'sort-order': feature.id === selectedGeocode.id ? 1 : 10
                            }
                        }
                    })

                }
            );

        }
    }, [selectedGeocode])




    return (


        <div ref={mapContainer} style={{ width: '100%', height: '100%' }} />

    );
};


export default GeocodeMapComponent;